import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "reactstrap";
import BreadCrumb from "../BreadCrumb/index";
import PageController from "../PageController/index";
import * as common from "../Common/common";
import DataSelector from "./dataselector";
import DataInfo from "../LandUse/dataInfoChild";
import { API, CLIENT } from "../../consts_path";
import { getToken } from "../../Actions/common";
import { updateGeoLocation, loading } from "../../Actions/landUse";

class Irrigate extends Component {
  componentDidMount() {
    const { getToken } = this.props;
    getToken();
  }

  updateFunc = async (parentKey, data, url, token, nextStep = true) => {
    const { history, input, setLoading } = this.props;
    const form = new FormData();
    const updateForUrl = common.makeUrlIndex(url, input.id);
    Object.keys(data.input).map((key) =>
      form.append(`${parentKey}[${key}]`, data[parentKey][key])
    );
    if (nextStep) {
      setLoading(true);
    }
    await fetch(updateForUrl, {
      credentials: "same-origin",
      method: "PUT",
      body: form,
      headers: {
        "X-CSRF-Token": token,
      },
    }).then((_res) => {
      if (!_res.ok) {
        alert("予期せぬ障害が発生しました。地点選択からやり直してください。");
        // throw Error((window.location = CLIENT.URL.root));
      } else {
        if (nextStep) {
          setLoading(false);
          history.push(
            common.makeUrlIndex(CLIENT.URL.segmentpartition, input.id)
          );
        }
      }
    });
  };

  update = (event, weather, irrigate) => {
    event.preventDefault();
    const input = Object.keys(weather)[0];
    const { csrfToken } = this.props;
    this.updateFunc(input, weather, API.URL.weather, csrfToken);
    this.updateFunc(input, irrigate, API.URL.irrigate, csrfToken, false);
  };

  render() {
    const { weather, irrigate, isLoading } = this.props;
    const assign = Object.assign({}, common.pageController);
    assign.display = { display: "block" };
    assign.displayN = { display: "block" };
    assign.url = [CLIENT.URL.root, ""];
    assign.value = ["戻る", "次へ"];
    return (
      <div>
        <BreadCrumb co2={0} irrigate={1} />
        <span>
          <DataInfo />
          <Form name="myForm" id="myF">
            <DataSelector />
          </Form>
          {!isLoading && (
            <PageController
              assign={assign}
              serverSendFunction={(e) => this.update(e, weather, irrigate)}
            />
          )}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  weather: state.weather,
  irrigate: state.irrigateData,
  isLoading: state.load,
  csrfToken: state.token,
});
const mapDispatchToProps = (dispatch) => ({
  getToken: (url) => dispatch(getToken(url)),
  updateGeo: (url, form, variable) =>
    dispatch(updateGeoLocation(url, form, variable)),
  setLoading: (val) => dispatch(loading(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Irrigate);
