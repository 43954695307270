import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "reactstrap";
import BreadCrumb from "../BreadCrumb/index";
import PageController from "../PageController/index";
import * as common from "../Common/common";
import DataSelector from "./dataselector";
import DataInfo from "../LandUse/dataInfoChild";
import { API, CLIENT } from "../../consts_path";
import { getToken } from "../../Actions/common";
import { updateGeoLocation, fetchGeoLocation } from "../../Actions/landUse";
import { parse } from "query-string";

const geo = {
  latitude: "",
  longitude: "",
  mesh_code: "",
  soil_code: "",
  soil_name: "",
  input_id: "",
};

class Co2 extends Component {
  componentDidMount() {
    const { getToken } = this.props;
    getToken();
  }

  componentDidUpdate(_prevProps, _) {
    const { input, location, updateGeo, fetchGeo, csrfToken } = this.props;
    const urlForGeo = API.URL.geolocation.replace(API.REP.inputid, input.id);
    const qry = location.search && parse(location.search);
    // if (csrfToken !== prevProps.csrfToken) {
      if (qry !== "") {
        qry.input_id = qry && input.id;
        const form = new FormData();
        const keys = Object.keys(qry);
        Object.keys(geo).map((v, i) =>
          form.append(`input[${v}]`, qry[keys[i]])
        );
        form.append("authenticity_token", csrfToken);
        updateGeo(urlForGeo, form, "UPDATE_GEO");
        fetchGeo(urlForGeo);
      } else {
        updateGeo(urlForGeo, null, "BACKTO");
      }
    // }
  }

  update = (event) => {
    event.preventDefault();
    const { history, location, input } = this.props;
    const qry = location.search && parse(location.search);
    if (qry === "") {
      alert("タイル レイヤーを選択する必要があります。");
    } else {
      history.push(
        common.makeUrlIndex(CLIENT.URL.weather, input.id)
      );
    }
  };

  render() {
    const { currentCoordinate } = this.props;
    const assign = Object.assign({}, common.pageController);
    assign.display = { display: "none" };
    assign.displayN = { display: "block" };
    assign.url = ["", ""];
    assign.value = ["", "次へ"];
    return (
      <div>
        <BreadCrumb co2={1} />
        <span>
          <DataInfo />
          <Form name="myForm" id="myF">
            <DataSelector />
          </Form>
          <PageController
            assign={assign}
            serverSendFunction={(e) => this.update(e, currentCoordinate)}
          />
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  currentCoordinate: state.currentCoordinate,
  irrigate: state.irrigateData,
  csrfToken: state.token,
});
const mapDispatchToProps = (dispatch) => ({
  getToken: (url) => dispatch(getToken(url)),
  updateGeo: (url, form, variable) =>
    dispatch(updateGeoLocation(url, form, variable)),
  fetchGeo: (url, qry) => dispatch(fetchGeoLocation(url, qry)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Co2);
