import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "reactstrap";
import { parse } from "query-string";
import { updateGeoLocation } from "../../Actions/landUse";
import { getToken } from "../../Actions/common";
import BreadCrumb from "../BreadCrumb/index";
import PageController from "../PageController/index";
import * as common from "../Common/common";
import DataSelector from "./dataselector";
import DataInfo from "./datainfo";
import { API, CLIENT } from "../../consts_path";

const geo = {
  latitude: "",
  longitude: "",
  mesh_code: "",
  soil_code: "",
  soil_name: "",
  input_id: "",
};

const updateFunc = async (parentKey, landuse, inputs, history, token) => {
  const form = new FormData();
  const updateForUrl = common.makeUrlIndex(API.URL.landuse, inputs.id);
  Object.keys(landuse.input).map((key) =>
    form.append(`${parentKey}[${key}]`, landuse[parentKey][key])
  );
  await fetch(updateForUrl, {
    credentials: "same-origin",
    method: "PUT",
    body: form,
    headers: {
      "X-CSRF-Token": token,
    },
  }).then((_res) => {
    if (!_res.ok) {
      alert("予期せぬ障害が発生しました。地点選択からやり直してください。");
      // throw Error((window.location = CLIENT.URL.root));
    } else {
      history.push(common.makeUrlIndex(CLIENT.URL.weather, inputs.id));
    }
  });
};

class LandUse extends Component {
  componentDidMount() {
    const { getToken } = this.props;
    getToken();
  }
  componentDidUpdate(prevProps, _) {
    const { input, location, updateGeo, csrfToken } = this.props;
    const urlForGeo = API.URL.geolocation.replace(API.REP.inputid, input.id);
    const qry = location.search && parse(location.search);
    if (csrfToken !== prevProps.csrfToken) {
      if (qry !== "") {
        qry.input_id = qry && input.id;
        const form = new FormData();
        const keys = Object.keys(qry);
        Object.keys(geo).map((v, i) =>
          form.append(`input[${v}]`, qry[keys[i]])
        );
        form.append("authenticity_token", csrfToken);
        updateGeo(urlForGeo, form, "UPDATE_GEO");
      } else {
        updateGeo(urlForGeo, null, "BACKTO");
      }
    }
  }
  update = (event, landUse, inputs) => {
    event.preventDefault();
    const { history, csrfToken } = this.props;
    const input = Object.keys(landUse)[0];
    updateFunc(input, landUse, inputs, history, csrfToken);
  };
  render() {
    const { landUse, input, location } = this.props;
    const assign = Object.assign({}, common.pageController);
    assign.display = { display: "none" };
    assign.displayN = { display: "block" };
    assign.url = ["", ""];
    assign.value = ["", "次へ"];
    return (
      <div>
        <BreadCrumb landuse={1} />
        <span>
          <DataInfo location={location} />
          <Form name="myForm" id="myF">
            <DataSelector />
          </Form>
          {landUse && (
            <PageController
              assign={assign}
              serverSendFunction={(e) => this.update(e, landUse, input)}
            />
          )}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  landUse: state.landUseData,
  upDateGeoStatus: state.updateGeoLocation,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  updateGeo: (url, form, variable) =>
    dispatch(updateGeoLocation(url, form, variable)),
  getToken: () => dispatch(getToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandUse);
